import React from "react"

import Content from "../Common/Content"
import Wrapper from "../Common/Wrapper"

import ContactImage from "../../images/contact/contact.png"

import { ContactWrapper, ImageWrapper, InfosWrapper} from "./styled"

const Contact= () => (
    <>
        <Wrapper>
            <Content>
                <ContactWrapper>
                    <InfosWrapper>
                        <h1>Contact</h1>
                        <p>Let's get in touch! For any project inquiries, open opportunities, or even simply to say hi 
                            you can connect with me via <a href="mailto:madisonawheeler@gmail.com">email</a>  and <a href="https://www.linkedin.com/in/madison-wheeler-aaaa67a4/">Linkedin</a>.</p>
                    </InfosWrapper>
                    <ImageWrapper>
                        <img src={ContactImage} alt="" />
                    </ImageWrapper>
                </ContactWrapper>
            </Content>
        </Wrapper>
    </> 
)

export default Contact
